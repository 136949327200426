<script>
import Layout from '@layouts/main/main'
const SideMenu = () => import('@components/settings-menu/settings-menu')
export default {
  components: { Layout, SideMenu },
}
</script>

<template>
  <Layout :class="$style.layout">
    <div :class="$style.container">
      <div :class="$style.sideMenu">
        <SideMenu />
      </div>
      <slot />
    </div>
  </Layout>
</template>

<style lang="scss" module>
@import '@design';

.container {
  display: flex;
  width: 100%;
  height: 100%;
}

.layout {
  width: 100%;
}

.sideMenu {
  display: flex;
  width: $settings-width;
  min-width: $settings-width;
}
</style>
